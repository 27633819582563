import React, { Component } from 'react';
import { connect } from 'react-redux';
import Rodal from 'rodal';
import Slider from "react-slick";
import SVG from 'react-inlinesvg';
import { Helmet } from "react-helmet";

import labels from 'constants/labels';

import BlockTitle from 'components/BlockTitle';

import './Media.scss';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <SVG
      src="assets/images/svgs/arrow-next.svg"
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick} />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <SVG
      src="assets/images/svgs/arrow-prev.svg"
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick} />
  );
}

class Media extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false
    };
  }

  onOpenModal = (index) => {
    this.setState({ isModalOpen: true });
    this.slider.slickGoTo(index);
  };

  onCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { currentLang: { lang } } = this.props;
    const gallery = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    const { isModalOpen } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      nextArrow: <NextArrow className="Media-arrow" />,
      prevArrow: <PrevArrow className="Media-arrow" />
    };

    return (
      <div className="Media">
        <Helmet>
          <meta
            name="description"
            content={labels[lang].media.meta.description}
          />
          <title>{labels[lang].media.meta.title}</title>
        </Helmet>

        <BlockTitle className="Media-title">{labels[lang].media.title}</BlockTitle>
        <div className="Media-gallery">
          {gallery.map((index, key) => (
            <div className="Media-image" key={key} onClick={(e) => this.onOpenModal(index - 1)}>
              <img src={"assets/images/gallery/" + index + ".jpg"} alt="" />
            </div>
          ))}
          <div className="clear"></div>
        </div>

        <Rodal
          visible={isModalOpen}
          onClose={this.onCloseModal.bind(this)}
          customStyles={{
            width: '80%',
            height: '600px'
          }}
          animation="fade"
          className="Media-modal">
          <Slider {...settings} ref={slider => (this.slider = slider)} className="Media-slider">
            {gallery.map((index, key) => (
              <div className="Media-slider-image" key={key}>
                <img src={"assets/images/gallery/" + index + ".jpg"} alt="" />
              </div>
            ))}
          </Slider>
        </Rodal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
 currentLang : state.lang
});

export default connect(mapStateToProps, null)(Media);
