import React, { Component } from 'react';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';
import { Helmet } from "react-helmet";

import labels from 'constants/labels';

import BlockTitle from 'components/BlockTitle';

import './Contact.scss';

class Contact extends Component {
  render() {
    const { currentLang: { lang } } = this.props;
    const contacts = labels[lang].contact.content;

    return (
      <div className="Contact">
        <Helmet>
          <meta
            name="description"
            content={labels[lang].contact.meta.description}
          />
          <title>{labels[lang].contact.meta.title}</title>
        </Helmet>

        <BlockTitle insert={true}>{labels[lang].contact.title}</BlockTitle>
        <div className="Contact-content">
          {contacts.map((contact, index) => (
            <div className="Contact-card" key={index}>
              <h5 className="Contact-card-fullname">{contact.fullname}</h5>
              <p className="Contact-card-address">{contact.address}</p>

              {contact.mail &&
                <p className="Contact-card-mail">
                  <div className="Contact-card-icon">
                    <SVG src="assets/images/svgs/envelope.svg" />
                  </div>
                  <a href={"mailto:" + contact.mail}>{contact.mail}</a>
                </p>
              }

              {contact.phone &&
                <p className="Contact-card-phone">
                  <div className="Contact-card-icon">
                    <SVG src="assets/images/svgs/phone.svg" />
                  </div>
                  <a href={"tel:" + contact.phone}>{contact.phone}</a>
                </p>
              }
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
 currentLang : state.lang
});

export default connect(mapStateToProps, null)(Contact);
