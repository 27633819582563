export default (state = {}, action) => {
 switch (action.type) {
  case 'LANG_SWITCH':
   return {
    lang: state.lang === 'en' ? 'fr' : 'en'
   }
  default:
   return state
 }
}
