export const push = () => dispatch => {
 dispatch({
  type: 'MENU_PUSH'
 })
}

export const slide = () => dispatch => {
 dispatch({
  type: 'INFOBOX_SLIDE'
 })
}

export default { push, slide };
