import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';

import Header from './Header';
import Footer from './Footer';

import './Layout.scss';

class Layout extends Component {
  constructor(props) {
    super(props);

    if(window.navigator.language !== 'fr-FR') {
      localStorage.setItem('lang', 'en');
    } else {
      localStorage.setItem('lang', 'fr');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scroll(0,0);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header />

        <div className="MainContent">
          {this.props.children}
        </div>

        <ScrollToTop showUnder={160}>
          <div className="ScrollToTop-content">
            <img src="assets/images/svgs/arrow-right.svg" alt="" className="ScrollToTop-arrow" />
          </div>
        </ScrollToTop>

        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
