import React, { Component } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import { Helmet } from "react-helmet";

import SectionHeading from 'components/SectionHeading';
import BlockImage from 'components/BlockImage';
import BlockIllu from 'components/BlockIllu';
import BlockMap from 'components/BlockMap';
import BlockMapAround from 'components/BlockMapAround';
import BlockImagesIcons from 'components/BlockImagesIcons';
import BlockImageInfo from 'components/BlockImageInfo';

import './Neighbourhood.scss';

class Neighbourhood extends Component {
  render() {
    const { labels } = this.props;

    return (
      <div className="Neighbourhood">
        <Helmet>
          <meta
            name="description"
            content={labels.neighbourhood.meta.description}
          />
          <title>{labels.neighbourhood.meta.title}</title>
        </Helmet>

        <SectionHeading content={labels.neighbourhood.heading} />
        <ScrollableAnchor id={'connexion'}>
          <BlockIllu content={labels.neighbourhood.blockIlluFull} position="left" />
        </ScrollableAnchor>
        <ScrollableAnchor id={'map'}>
          <BlockMap content={labels.neighbourhood.map} />
        </ScrollableAnchor>
        <BlockImage content={labels.neighbourhood.blockImage} position="left" />
        <ScrollableAnchor id={'inovel'}>
          <BlockMapAround content={labels.neighbourhood.mapAround} className="up" />
        </ScrollableAnchor>
        <BlockIllu content={labels.neighbourhood.blockIllu} position="left" isFull={false} />
        <ScrollableAnchor id={'mall'}>
          <BlockImageInfo content={labels.neighbourhood.blockImageInfo} />
        </ScrollableAnchor>
        <BlockImagesIcons content={labels.neighbourhood.blockImagesIcons} />
      </div>
    );
  }
}

export default Neighbourhood;
