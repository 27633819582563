export default (state = {}, action) => {

  switch (action.type) {
    case 'MENU_PUSH':
      return {
        isMenuOpen: !state.isMenuOpen
      }

    case 'INFOBOX_SLIDE':
      return {
        isInfoboxOpen: !state.isInfoboxOpen
      }

    default:
      return state
    }
}
