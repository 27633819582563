import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import labels from 'constants/labels';

import BlockTitle from 'components/BlockTitle';

import './LegalNotices.scss';

class LegalNotices extends Component {
  render() {
    const { currentLang: { lang } } = this.props;

    return (
      <div className="LegalNotices">
        <Helmet>
          <meta
            name="description"
            content={labels[lang].legalNotices.meta.description}
          />
          <title>{labels[lang].legalNotices.meta.title}</title>
        </Helmet>

        <BlockTitle insert={true}>{labels[lang].legalNotices.title}</BlockTitle>
        <div className="LegalNotices-content" dangerouslySetInnerHTML={{__html: labels[lang].legalNotices.content}}></div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
 currentLang : state.lang
});

export default connect(mapStateToProps, null)(LegalNotices);
