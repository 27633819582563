import React, { Component } from 'react';

import "./Axono.scss";

class Axono extends Component {
  render() {
    const { content } = this.props;

    return (
      <div className="Axono">
        <div className="Axono-image">
          <img src={content.image} alt="" />
        </div>
        <div className="Axono-data">
          <h4 className="Axono-title" dangerouslySetInnerHTML={{__html: content.data.title}}></h4>
          <table className="Axono-table">
            <thead>
              <tr>
                <th></th>
                <th>B</th>
                <th>C</th>
                <th>A</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              {content.data.table.map((d, key) => (
                <tr key={key}>
                  <td>{d.floor}</td>
                  <td>{d.b}</td>
                  <td>{d.c}</td>
                  <td>{d.a}</td>
                  <td>{d.total}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="Axono-insert">
            <div className="Axono-insert-content">
              <p dangerouslySetInnerHTML={{__html: content.insert}}></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Axono;
