import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import labels from 'constants/labels';

import Button from 'components/Button';

import './Footer.scss';

class Footer extends Component {
  render() {
    const { currentLang : { lang } } = this.props;

    return (
      <div className="Footer">
        <div className="Footer-brochure">
          <div className="Footer-content">
            <div className="Footer-brochure-content">
              <div className="Footer-brochure-left">
                <img src="assets/images/brochure.png" alt="Téléchargez la brochure" className="Footer-brochure-image"/>
              </div>
              <div className="Footer-brochure-right">
                <p className="Footer-brochure-text white bold">{labels[lang].footer.pre.text}</p>
                <Button href="assets/pdf/brochure.pdf">{labels[lang].footer.pre.button}</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="Footer-nav">
          <div className="Footer-content">
            <div className="Footer-nav-left">
              <SVG src="assets/images/svgs/crystalys.svg" title="Crystalys" className="Footer-nav-logo" />
              <p>6 avenue Morane Saulnier&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Vélizy-Vilacoublay</p>
            </div>
            <div className="Footer-nav-right">
              <p>{labels[lang].footer.main.rules}</p><span className="Footer-nav-separator">|</span>
              <p><NavLink to={labels[lang].footer.main.link.to} className="Footer-nav-link">{labels[lang].footer.main.link.label}</NavLink></p><span className="Footer-nav-separator">|</span>
              <p>design by
                <a href="https://www.agence-tactile.fr/" target="_blank" rel="noopener noreferrer">
                  <SVG src="assets/images/svgs/tactile.svg" title="Agence Tactile" className="Footer-nav-tactile" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
 currentLang : state.lang
});

export default connect(mapStateToProps, null)(Footer);
