import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";

import App from './App';
import Layout from './components/Layout';

import configureStore from './store';
import * as serviceWorker from './serviceWorker';

import 'rodal/lib/rodal.css';
import './styles/fonts.css';
import './styles/animate.scss';
import './styles/app.scss';

ReactDOM.render(
  <Provider store={configureStore()}>
    <Router>
      <Layout>
        <App />
      </Layout>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
