import React, { Component } from 'react';

import "./BlockImageInfo.scss";

class BlockImageInfo extends Component {
  render() {
    const { content } = this.props;

    return (
      <div className="BlockImageInfo">
        <img src={content.image} alt="" className="BlockImageInfo-image" />

        <div className="BlockImageInfo-content">
          <div className="BlockImageInfo-column">
            <img src={content.icon} alt="" />
            <h5 dangerouslySetInnerHTML={{__html: content.title}}></h5>
          </div>
          <div className="BlockImageInfo-column">
            <ul className="BlockImageInfo-data">
            {content.data.map((d, key) => (
              <li key={key} className="BlockImageInfo-data-text">
                <span dangerouslySetInnerHTML={{__html: d}}></span>
              </li>
            ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default BlockImageInfo;
