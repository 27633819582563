import React from 'react';

import { Switch, Route, withRouter } from 'react-router-dom';

import Home from 'pages/Home';
import Media from 'pages/Media';
import Contact from 'pages/Contact';
import LegalNotices from 'pages/LegalNotices';

function App() {
  return (
    <Switch>
      <Route exact path={`/`} render={(props) => <Home slideIndex={0} {...props} />} />
      <Route exact path={`/home`} render={(props) => <Home slideIndex={1} {...props} />} />
      <Route exact path={`/immeuble`} render={(props) => <Home slideIndex={2} {...props} />} />
      <Route exact path={`/services`} render={(props) => <Home slideIndex={3} {...props} />} />
      <Route exact path={`/quartier`} render={(props) => <Home slideIndex={4} {...props} />} />

      <Route exact path={`/media`} component={Media} />

      <Route exact path={`/contact`} component={Contact} />
      <Route exact path={`/mentions-legales`} component={LegalNotices} />
    </Switch>
  );
}

export default withRouter(App);
