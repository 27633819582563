import React, { Component } from 'react';

import Plan from './Plan';

import './Plans.scss';

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0
    };
  }

  onChangeSlide(index) {
    this.setState({currentSlide: index});
  }

  render() {
    const { content } = this.props;
    const { currentSlide } = this.state;

    return (
      <div className="Plans">
        {content.map((c, index) => (
          <Plan content={c} key={index} nextIndex={(index + 1) === content.length ? 0 : index + 1} className={index === currentSlide ? 'active' : ''} onChangeSlide={this.onChangeSlide.bind(this)}></Plan>
        ))}
      </div>
    )
  }
}

export default Plans;
