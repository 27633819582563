import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {
  lang: {
    lang: window.navigator.language === 'fr-FR' ? 'fr' : 'en'
  }
};

export default function configureStore() {
 return createStore(
   rootReducer,
   initialState,
   applyMiddleware(thunk)
 );
}
