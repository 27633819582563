import React, { Component } from 'react';

import "./BlockMap.scss";

class BlockMap extends Component {
  render() {
    const { className, content } = this.props;

    return (
      <div className={"BlockMap " + className}>
        <div className="BlockMap-content">
          <div className="BlockMap-data">
            <h4 className="BlockMap-title">{content.title}</h4>
            <table className="BlockMap-table">
              <tbody>
                {content.data.map((d, key) => (
                  <tr key={key}>
                    <td>{d.time}</td>
                    <td>{d.from}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <img src="assets/images/neighbourhood/map-mobile.jpg" alt="" className="BlockMap-image-mobile" />
        </div>
      </div>
    )
  }
}

export default BlockMap;
