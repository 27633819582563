import React, { Component } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import { Helmet } from "react-helmet";

import SectionHeading from 'components/SectionHeading';
import BlockImage from 'components/BlockImage';
import BlockIllu from 'components/BlockIllu';
import BlockPerspective from 'components/BlockPerspective';
import BlockIcons from 'components/BlockIcons';
import BlockPartners from 'components/BlockPartners';
import BlockImagesIcons from 'components/BlockImagesIcons';

import './Services.scss';

class Services extends Component {
  render() {
    const { labels } = this.props;

    return (
      <div className="Services">
        <Helmet>
          <meta
            name="description"
            content={labels.services.meta.description}
          />
          <title>{labels.services.meta.title}</title>
        </Helmet>

        <SectionHeading content={labels.services.heading} imageWidth="400" nextSectionTitle={labels.neighbourhood.heading.title} />

        <ScrollableAnchor id={'exchange'}>
          <BlockPerspective content={labels.services.blockPerspectiveHall} className="up" hasOverlay={false} />
        </ScrollableAnchor>
        <BlockIllu content={labels.services.blockIllu} isFull={false} />
        <ScrollableAnchor id={'hall'}>
          <BlockIcons content={labels.services.blockIcons} />
        </ScrollableAnchor>
        <BlockPerspective content={labels.services.blockPerspectiveTeam} isFull={false} />
        <BlockIllu content={labels.services.blockIlluFull} position="left" />
        <ScrollableAnchor id={'restaurant'}>
          <BlockImagesIcons content={labels.services.blockImagesIcons} />
        </ScrollableAnchor>
        <BlockPerspective content={labels.services.blockPerspective} isFull={false} />
        <BlockImage content={labels.services.blockImage} position="left" />
        <ScrollableAnchor id={'certifications'}>
          <BlockPartners content={labels.services.blockPartners} />
        </ScrollableAnchor>
      </div>
    );
  }
}

export default Services;
