import React, { Component } from 'react';

import Button from 'components/Button';

import "./Plan.scss";

class Plan extends Component {
  render() {
    const { className, content, nextIndex, onChangeSlide } = this.props;

    return (
      <div className={className + " Plan"}>
        <div className="Plan-content">
          <div className="Plan-image">
            <img src={content.image} alt="" />
            <div className="Plan-insert">
              <h5 className="Plan-insert-title">{content.insert.title}</h5>
              <p className="Plan-insert-text">{content.insert.text}</p>
              <p className="Plan-insert-number" dangerouslySetInnerHTML={{__html: content.insert.number}}></p>
            </div>
          </div>
          <div className="Plan-data">
              <table className="Plan-table">
              <tbody>
                {content.data.map((d, key) => (
                  <tr key={key}>
                    <td>{d.number}</td>
                    <td>{d.text}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="Plan-arrangement" onClick={() => onChangeSlide(nextIndex)}>
              <h4 className="Plan-arrangement-title" dangerouslySetInnerHTML={{__html: content.arrangement.title}}></h4>
              <Button direction="right" className="Plan-arrangement-button" onlyLabel>{content.arrangement.button}</Button>
            </div>
          </div>
          <div className="Plan-caption">
            <img src={content.caption} alt="" />
          </div>
        </div>
      </div>
    )
  }
}

export default Plan;
