import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import Button from 'components/Button';

import "./BlockPerspective.scss";

class BlockPerspective extends Component {
  render() {
    const { className, content, isFull = true, hasOverlay = true } = this.props;

    return (
      <div className={"BlockPerspective " + className + (isFull ? " full": " condensed")} style={{backgroundImage: `url(${content.image})`}}>
        <div className={ hasOverlay ? "BlockPerspective-overlay" : "" }>
          <div className="BlockPerspective-content">
            <div className="BlockPerspective-info">
              {content.icon &&
                <SVG src={content.icon} fill="#fff" />
              }
              {content.text &&
                <p className="BlockPerspective-info-text" dangerouslySetInnerHTML={{__html: content.text}}></p>
              }
              {content.subtext &&
                <p className="BlockPerspective-info-subtext" dangerouslySetInnerHTML={{__html: content.subtext}}></p>
              }
            </div>
            {content.button &&
              <Button direction="right" className="BlockPerspective-button" to={content.button.to}>{content.button.label}</Button>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default BlockPerspective;
