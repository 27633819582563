import React, { Component } from 'react';

import "./BlockTitle.scss";

class BlockTitle extends Component {
  render() {
    const { children, insert, className } = this.props;
    
    return (
      <div className={"BlockTitle " + className}>
        {insert &&
          <div className="BlockTitle-insert"></div>
        }
        <h2 className="BlockTitle-text" dangerouslySetInnerHTML={{__html: children}}></h2>
      </div>
    )
  }
}

export default BlockTitle;
