import React, { Component } from 'react';

import Button from 'components/Button';

import "./BlockIllu.scss";

class BlockIllu extends Component {
  render() {
    const { content, position = 'right', isFull = true } = this.props;

    return (
      <div className={"BlockIllu " + position + (isFull ? " full" : " condensed")}>
        <div className="BlockIllu-content">
          <div className="BlockIllu-info">
            <h3 className="BlockIllu-title" dangerouslySetInnerHTML={{__html: content.title}}></h3>
            <p className="BlockIllu-text" dangerouslySetInnerHTML={{__html: content.text}}></p>
            <Button direction="bottom" className="BlockIllu-button" href={content.button.href ? content.button.href : null}>{content.button.label}</Button>
          </div>
          {content.image &&
            <div className="BlockIllu-image">
              <img src={content.image} alt="" />
            </div>
          }
        </div>
      </div>
    )
  }
}

export default BlockIllu;
