import React, { Component } from 'react';

import Button from 'components/Button';

import "./BlockImage.scss";

class BlockImage extends Component {
  render() {
    const { content, position = 'right' } = this.props;

    return (
      <div className="BlockImage" style={{backgroundImage: `url(${content.image})`}}>
        <div className="BlockImage-overlay">
          <div className={"BlockImage-content " + position}>
            <div className="BlockImage-info">
              <h3 className="BlockImage-title" dangerouslySetInnerHTML={{__html: content.title}}></h3>
              <p className="BlockImage-text" dangerouslySetInnerHTML={{__html: content.text}}></p>
              <Button direction="bottom" className="BlockImage-button" href={content.button.href}>{content.button.label}</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BlockImage;
