import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";

import labels from 'constants/labels';

import SectionHeading from 'components/SectionHeading';

import Building from 'pages/Building';
import Services from 'pages/Services';
import Neighbourhood from 'pages/Neighbourhood';

import './Home.scss';

class Home extends Component {
  constructor(props) {
    super(props);
    this.slickRef = React.createRef();
    this.state = {
      currentIndex: this.props.slideIndex,
      isFadeOut: true
    }
  }

  componentDidMount() {
    this.changeSlide(this.props.slideIndex);
  }

  componentDidUpdate(prevProps) {
    if (this.props.slideIndex !== prevProps.slideIndex) {
      this.changeSlide(this.props.slideIndex);
    }
  }

  changeSlide(index) {
    const slick = this.slickRef.current;
    slick.slickGoTo(index);
  }

  render() {
    const { currentLang: { lang }, slideIndex } = this.props;
    const { currentIndex, isFadeOut } = this.state;

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: false,
        arrow: false,
        initialSlide: 0,
        infinite: false,
        adaptiveHeight: true,
        beforeChange: (current, next) => {
          const dots = document.querySelector(".slick-dots");
          this.setState({currentIndex : next});

          if(next === 0) {
            dots.style.visibility = 'hidden';
            dots.style.opacity = 0;

            this.setState({isFadeOut: true});
          } else {
            dots.style.visibility = 'visible';
            dots.style.opacity = 1;
          }
        }
    };

    if(currentIndex === 0) {
      const that = this;

      const delayChangeSlide = setTimeout(function() {
        that.changeSlide(1);
        clearTimeout(delayChangeSlide);
      }, 3500);

      const delayFadeOut = setTimeout(function() {
        that.setState({isFadeOut: false});
        clearTimeout(delayFadeOut);
      }, 4500);
    }

    return (
      <div className="App">
        <Slider {...settings} ref={this.slickRef}>
          <div className={"Home" + (isFadeOut ? ' fadeOut' : '')}>
            <div className="Home-intro">
              <img src="assets/images/video-fallback.jpg" alt="Crystalys" />
            </div>
          </div>

          <div className="Home">
            <SectionHeading content={labels[lang].home.heading} nextSectionTitle={labels[lang].building.heading.title} />
          </div>

          <Building labels={labels[lang]} />
          <Services labels={labels[lang]} />
          <Neighbourhood labels={labels[lang]} />
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
 currentLang : state.lang
});

export default connect(mapStateToProps, null)(withRouter(Home));
