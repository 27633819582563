import React, { Component } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import { Helmet } from "react-helmet";

import SectionHeading from 'components/SectionHeading';
import BlockImage from 'components/BlockImage';
import BlockIllu from 'components/BlockIllu';
import BlockPerspective from 'components/BlockPerspective';
import Axono from 'components/Axono';
import Plans from 'components/Plans';

import './Building.scss';

class Building extends Component {
  render() {
    const { labels } = this.props;

    return (
      <div className="Building">
        <Helmet>
          <meta
            name="description"
            content={labels.building.meta.description}
          />
          <title>{labels.building.meta.title}</title>
        </Helmet>

        <SectionHeading content={labels.building.heading} imageWidth="500" nextSectionTitle={labels.services.heading.title} />
        <ScrollableAnchor id={'building'}>
          <BlockImage content={labels.building.blockImage} />
        </ScrollableAnchor>
        <ScrollableAnchor id={'axono'}>
          <Axono content={labels.building.axono} />
        </ScrollableAnchor>
        <BlockIllu content={labels.building.blockIllu} />
        <BlockPerspective content={labels.building.blockPerspective} />
        <ScrollableAnchor id={'arrangement'}>
          <Plans content={labels.building.plans} />
        </ScrollableAnchor>
      </div>
    );
  }
}

export default Building;
