import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import "./Button.scss";

class Button extends Component {
  render() {
    const { href, to, direction = 'right', children, onlyLabel, className } = this.props;

    return (
      <div className={"Button " + className}>
        {href &&
          <a href={href} className="Button-link">{children}</a>
        }
        {to &&
          <Link to={to} className="Button-link">{children}</Link>
        }
        {onlyLabel &&
          <span className="Button-link">{children}</span>
        }
        <img src="assets/images/svgs/arrow-right.svg" alt="" className={"Button-image " + direction}/>
      </div>
    )
  }
}

export default Button;
