import React, { Component } from 'react';

import "./BlockPartners.scss";

class BlockPartners extends Component {
  render() {
    const { content } = this.props;

    return (
      <div className="BlockPartners">
        {content.partners.map((partner, key) => (
          <div className="BlockPartners-item" key={key}>
            <div className="BlockPartners-item-logo">
              <img src={partner.logo} alt="" />
            </div>
            <p dangerouslySetInnerHTML={{__html: partner.text}} className="BlockPartners-item-text"></p>
            <img src={partner.certification} alt="" className="BlockPartners-item-certification"/>
          </div>
        ))}
      </div>
    )
  }
}

export default BlockPartners;
