import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import "./BlockImagesIcons.scss";

class BlockImagesIcons extends Component {
  render() {
    const { content } = this.props;

    return (
      <div className="BlockImagesIcons">
        <div className="BlockImagesIcons-column black">
          <div className="BlockImagesIcons-column-content first">
            <div className="BlockImagesIcons-icons">
              <SVG src={content.illustrations.black} alt="" />
            </div>

            <div className="BlockImagesIcons-text">
              <p dangerouslySetInnerHTML={{__html: content.text[0]}}></p>
              <p dangerouslySetInnerHTML={{__html: content.text[1]}}></p>
            </div>
          </div>
        </div>

        <div className="BlockImagesIcons-column white">
          <div className="BlockImagesIcons-column-overlay"></div>
          <div className="BlockImagesIcons-column-content second">
            <div className="BlockImagesIcons-icons">
              <SVG src={content.illustrations.white} alt="" />
            </div>

            <div className="BlockImagesIcons-text">
              <p dangerouslySetInnerHTML={{__html: content.text[2]}}></p>
            </div>
          </div>

          <img src={content.image} alt="" className="BlockImagesIcons-image" />
        </div>
      </div>
    )
  }
}

export default BlockImagesIcons;
