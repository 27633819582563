import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import "./BlockIcons.scss";

class BlockIcons extends Component {
  render() {
    const { content } = this.props;

    return (
      <div className="BlockIcons">
        {content.icons.map((icon, key) => (
          <div className="BlockIcons-item" key={key}>
            <div className="BlockIcons-item-icon">
              <SVG src={icon.src} />
            </div>
            <p dangerouslySetInnerHTML={{__html: icon.text}} className="BlockIcons-item-text"></p>
          </div>
        ))}
      </div>
    )
  }
}

export default BlockIcons;
