import React, { Component } from 'react';
import SVG from 'react-inlinesvg';

import Button from 'components/Button';

import "./SectionHeading.scss";

class SectionHeading extends Component {
  render() {
    const { content, imageWidth, nextSectionTitle } = this.props;

    return (
      <div className="SectionHeading">
        <div className="SectionHeading-insert">
          <div className="SectionHeading-titles">
            <h2 dangerouslySetInnerHTML={{__html: content.title}} className="SectionHeading-title"></h2>
            <h4 dangerouslySetInnerHTML={{__html: content.subtitle}} className="SectionHeading-subtitle"></h4>
          </div>
          {content.button &&
            <Button direction="bottom" className="SectionHeading-button" href={content.button.href}>{content.button.label}</Button>
          }
        </div>
        <div className="SectionHeading-image">
          <img src={content.image} width={imageWidth} alt={content.title} />
        </div>

        {nextSectionTitle &&
          <div className="SectionHeading-next">
            <h2 className="SectionHeading-next-title" dangerouslySetInnerHTML={{__html: nextSectionTitle}}></h2>
          </div>
        }
      </div>
    )
  }
}

export default SectionHeading;
