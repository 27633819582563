import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

import labels from 'constants/labels';

import { push } from 'actions/animations';
import { switchLang } from 'actions/lang';

import './Header.scss';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: localStorage.getItem('lang'),
      isMenuOpen: false
    };
  }

  pushMenu = (event) => {
   this.props.push();
  }

  switchLang = (event) => {
   document.title = labels[this.props.currentLang.lang].titlePage;
   this.props.switchLang();
  }

  openMenu() {
    console.log('openMenu');
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  render() {
    const { currentLang : { lang } } = this.props;
    const { isMenuOpen } = this.state;

    return (
      <div className="Header">
        <div className="Header-band">
          <div className="Header-logo">
            <NavLink to="/">
              <img src="assets/images/svgs/crystalys.svg" alt="Crystalys" />
            </NavLink>
          </div>
          <div className="Header-menu" onClick={this.openMenu.bind(this)}>
            {isMenuOpen ? (
              <img className="Header-menu-icon" width="25" src="assets/images/svgs/close.svg" alt="" />
            ) : (
              <img className="Header-menu-icon" width="25" src="assets/images/svgs/burger-menu.svg" alt="" />
            )}
          </div>
        </div>

        <div className={"Header-nav" + (isMenuOpen ? " open" : " closed")}>
          <ul>
            {labels[lang].menu.links.map((link, key) => (
              <li className="Header-link" key={key}>
                <NavLink to={link.to} activeClassName="active">{link.label}</NavLink>
              </li>
            ))}
            <li className="Header-dash"></li>
            <li className="Header-link Header-icon">
              <NavLink to="/media" activeClassName="active">
                <img src="assets/images/svgs/camera.svg" alt="Medias" />
              </NavLink>
            </li>
            <li className="Header-link Header-icon">
              <NavLink to="/contact" activeClassName="active">
                <img src="assets/images/svgs/pencil.svg" alt="Contact" />
              </NavLink>
            </li>
            <li className="Header-link Header-icon">
              <a href="assets/pdf/brochure.pdf" target="_blank">
                <img src="assets/images/svgs/download.svg" alt="Download brochure" />
              </a>
            </li>
            <li className="Header-link Header-lang" onClick={this.switchLang}>
              {lang === 'fr' ? 'en' : 'fr'}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
 currentLang : state.lang,
 isMenuOpen : state.animations.isMenuOpen
});

const mapDispatchToProps = dispatch => ({
  push: () => dispatch(push()),
  switchLang: () => dispatch(switchLang())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
