import React, { Component } from 'react';

import "./BlockMapAround.scss";

class BlockMapAround extends Component {
  render() {
    const { className, content } = this.props;

    return (
      <div className={"BlockMapAround " + className}>
        <div className="BlockMapAround-content">
          <div className="BlockMapAround-data">
            <h4 className="BlockMapAround-title">{content.title}</h4>
            <ul className="BlockMapAround-list">
                {content.data.map((d, key) => (
                  <li key={key} className="BlockMapAround-list-item">
                    <span className="BlockMapAround-list-text" dangerouslySetInnerHTML={{__html: d}}></span>
                  </li>
                ))}
            </ul>
          </div>
          <img src="assets/images/neighbourhood/map-around-mobile.jpg" alt="" className="BlockMapAround-image-mobile" />
        </div>
      </div>
    )
  }
}

export default BlockMapAround;
